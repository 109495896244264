@font-face {
  font-family: "Plantin MT Pro";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/Monotype-PlantinMTPro-Bold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/Monotype-PlantinMTPro-Bold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/Monotype-PlantinMTPro-Bold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/Monotype-PlantinMTPro-Bold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/Monotype-PlantinMTPro-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/Monotype-PlantinMTPro-Bold.svg#PlantinMTPro-Bold")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommons";
  font-style: normal;
  src: url("./assets/fonts/TypeType-TTCommonsRegular.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsRegular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsRegular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsRegular.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsRegular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsRegular.svg#TTCommons")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommons";
  font-style: normal;
  font-weight: bold;
  src: url("./assets/fonts/TypeType-TTCommonsBold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsBold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsBold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsBold.svg#TTCommonsBold")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommonsBlack";
  font-style: normal;
  src: url("./assets/fonts/TypeType-TTCommonsBlack.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsBlack.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsBlack.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsBlack.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsBlack.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsBlack.svg#TTCommonsBlack")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommonsExtraBold";
  font-style: normal;
  src: url("./assets/fonts/TypeType-TTCommonsExtraBold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsExtraBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsExtraBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsExtraBold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsExtraBold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsExtraBold.svg#TTCommonsExtraBold")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommonsDemiBold";
  font-style: normal;
  src: url("./assets/fonts/TypeType-TTCommonsDemiBold.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsDemiBold.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsDemiBold.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsDemiBold.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsDemiBold.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsDemiBold.svg#TTCommonsDemiBold")
      format("svg"); /* Legacy iOS */
}

@font-face {
  font-family: "TTCommonsLight";
  font-style: normal;
  src: url("./assets/fonts/TypeType-TTCommonsLight.eot"); /* IE9 Compat Modes */
  src: url("./assets/fonts/TypeType-TTCommonsLight.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("./assets/fonts/TypeType-TTCommonsLight.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsLight.woff") format("woff"),
    /* Pretty Modern Browsers */
      url("./assets/fonts/TypeType-TTCommonsLight.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("./assets/fonts/TypeType-TTCommonsLight.svg#TTCommonsLight")
      format("svg"); /* Legacy iOS */
}

html {
  scroll-snap-type: y mandatory;
}

body {
  margin: 0;
  font-family: "TTCommons", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-snap-type: y mandatory;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
input:focus,
select:focus,
textarea:focus,
button:focus,
fieldset,
fieldset:focus {
  outline: none !important;
}

.typeWriter {
  margin-top: -150px;
}

.typeWriter h1 {
  font-family: "TTCommons", sans-serif;
  font-weight: bold;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 5px solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: -0.04em; /* Adjust as needed */
  animation: typing 3s steps(19, end), blink-caret 1s step-end infinite;
}

.typeWriter h1 span {
  font-family: "Plantin MT Pro", sans-serif;
}

.typeWriterContact h1 {
  font-family: "TTCommons", sans-serif;
  font-weight: bold;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 5px solid black; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: -0.04em; /* Adjust as needed */
  animation: typing 3s steps(19, end), blink-caretunder 1s step-end infinite;
}

.blinker {
  animation: blink-caretunder 1s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: black;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caretunder {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}
